<template>
   <div>
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="Acceso denegado" ref="denied_notification" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <ExportExcel ref="excelexport" />
      <Notas ref="notas" @result_ok="notaEdited()" />
      <v-data-table
         :headers="headers"
         :items="rows"
         :options.sync="options"
         :loading="loading"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
         :page.sync="page"
         class="ma-3"
         :server-items-length="totalItems"
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  @input="search = search.slice(0, 30)"
               ></v-text-field>
               <v-btn color="primary" @click="$refs.excelexport.Show()" class="d-none d-sm-flex ml-3">
                  <v-icon left>mdi-microsoft-excel</v-icon>Exportar excel
               </v-btn>
               <v-btn color="accent" @click="$refs.excelexport.Show()" class="d-flex d-sm-none ml-3 mr-1" icon small>
                  <v-icon small>mdi-microsoft-excel</v-icon>
               </v-btn>
            </v-toolbar>
         </template>
         <template v-slot:[`item.fecha_hora`]="{ item }">
            {{ displayFechaHora(item.fecha_hora) }}
         </template>
         <template v-slot:[`item.notas`]="{ item }">
            <div class="d-flex">
               {{ item.notas }}
               <div v-if="!item.folio_reporte && canEditNota">
                  <v-btn x-small icon @click="editNota(item)"><v-icon x-small>mdi-pencil</v-icon></v-btn>
               </div>
            </div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Notification from "../Notification.vue";
import Notas from "./Movements/Notas.vue";
import ExportExcel from "./Movements/ExportExcel.vue";
import c from "../../const";
import moment from "moment";
import timezone from "moment-timezone";

export default {
   components: {
      Notification,
      ExportExcel,
      Notas,
   },
   data: () => ({
      headers: [
         { text: "ID", value: "id_movimiento" },
         { text: "Folio", value: "folio_reporte" },
         { text: "Cliente", value: "nombre_cliente" },
         { text: "Inicial", value: "inicial" },
         { text: "Agregado", value: "agregado" },
         { text: "Descontado", value: "descontado" },
         { text: "Final", value: "final" },
         { text: "Fecha Hora", value: "fecha_hora" },
         { text: "Notas", value: "notas" },
         { text: "Username", value: "username" },
      ],
      rows: [],
      options: {
         page: 1,
         itemsPerPage: 10,
         sortBy: ["id_movimiento"],
         sortDesc: [true],
      },
      search: "",
      editedIndex: -1,
      loading: false,
      totalItems: 0,
      page: 1,
   }),
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   created() {
      try {
         if (localStorage.getItem("movs_options")) {
            this.options = JSON.parse(localStorage.getItem("movs_options"));
         } else {
            this.options = {
               page: 1,
               itemsPerPage: 10,
               sortBy: ["id_movimiento"],
               sortDesc: [true],
            };
            localStorage.setItem("movs_options", JSON.stringify(this.options));
         }
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["id_movimiento"],
            sortDesc: [true],
         };
         localStorage.setItem("movs_options", JSON.stringify(this.options));
      }
   },
   methods: {
      async initialize() {
         try {
            this.loading = true;
            await this.$store.dispatch("getMovimientos", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
               TZ: timezone.tz.guess(),
            });
            this.rows = this.$store.state.movimientos.movements;
            this.totalItems = this.$store.state.movimientos.totalItems;
            localStorage.setItem("movs_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.loading = false;
         }
      },
      displayFechaHora(val) {
         return moment(val).format("HH:mm DD/MM/YYYY");
      },
      editNota(item) {
         this.$refs.notas.Show(item);
      },
      notaEdited(){
         this.$refs.ok_noti.Show();
         this.initialize();
      }
   },
   computed:{
      canEditNota: function(){
         if(this.$store.getters.isSuperOrAdmin){
            return true;
         }
         return this.$store.state.userdata.permisos.includes(c.PERMISOS.EDITAR_NOTAS_DE_MOVIMIENTOS)
      }
   }
};
</script>
