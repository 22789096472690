<template>
   <v-dialog v-model="show" max-width="290">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>Editar nota</v-card-title>
         <v-card-text>
            <v-text-field
               label="Notas"
               v-model="notas"
               required
               :error-messages="notasErrors"
               @input="$v.notas.$touch()"
               @blur="$v.notas.$touch()"
            ></v-text-field>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="primary" @click="dialogResult_OK" :loading="isloading">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      notas: {
         required,
         maxLength: maxLength(255),
      },
   },
   data: () => {
      return {
         show: false,
         notas: "",
         id_movimiento: 0,
         isloading: false,
      };
   },
   computed: {
      notasErrors() {
         const errors = [];
         if (!this.$v.notas.$dirty) return errors;
         !this.$v.notas.required && errors.push("Requerido");
         !this.$v.notas.maxLength && errors.push("Debe contener maximo 255 caracteres");
         return errors;
      },
   },
   methods: {
      dialogResult_OK: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;

               res = await this.$store.dispatch("updateMov", {
                  id: this.id_movimiento,
                  notas: this.notas,
               });

               if (res == true) {
                  this.show = false;
                  this.$emit("result_ok");
               } else {
                  throw res;
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$emit("result_cancel");
      },
      Show(item) {
         this.notas = item.notas;
         this.id_movimiento = item.id_movimiento;
         this.isloading = false;
         this.show = true;
      },
   },
};
</script>
