<template>
   <v-dialog v-model="show" max-width="290">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>Exportar a excel</v-card-title>
         <v-card-text>
            <v-menu v-model="show_init" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
               <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                     v-model="init"
                     label="Fecha inicial"
                     append-icon="mdi-calendar"
                     readonly
                     v-bind="attrs"
                     v-on="on"
                     @input="
                        () => {
                           input();
                           $v.init.$touch();
                        }
                     "
                     :error-messages="initErrors"
                     required
                     @blur="$v.init.$touch()"
                  ></v-text-field>
               </template>
               <v-date-picker v-model="init" @input="show_init = false"></v-date-picker>
            </v-menu>
            <v-menu v-model="show_final" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
               <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                     v-model="final"
                     label="Fecha final"
                     append-icon="mdi-calendar"
                     readonly
                     v-bind="attrs"
                     v-on="on"
                     @input="
                        () => {
                           input();
                           $v.final.$touch();
                        }
                     "
                     :error-messages="finalErrors"
                     required
                     @blur="$v.final.$touch()"
                  ></v-text-field>
               </template>
               <v-date-picker v-model="final" @input="show_final = false"></v-date-picker>
            </v-menu>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="primary" @click="dialogResult_OK" :loading="isloading">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { saveAs } from "file-saver";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      init: {
         date: function () {
            if (this.init.trim().length > 0) {
               if (moment(this.init).isValid()) {
                  return true;
               }
            }
            return false;
         },
         required,
      },
      final: {
         date: function () {
            if (this.final.trim().length > 0) {
               if (moment(this.final).isValid()) {
                  return true;
               }
            }
            return false;
         },
         required,
      },
   },
   data: () => {
      return {
         init: "",
         final: "",
         show: false,
         show_init: false,
         show_final: false,
         isloading: false,
      };
   },
   computed: {
      initErrors() {
         const errors = [];
         if (!this.$v.init.$dirty) return errors;
         !this.$v.init.required && errors.push("Requerido");
         !this.$v.init.date && errors.push("Fecha no valida");
         return errors;
      },
      finalErrors() {
         const errors = [];
         if (!this.$v.final.$dirty) return errors;
         !this.$v.final.required && errors.push("Requerido");
         !this.$v.final.date && errors.push("Fecha no valida");
         return errors;
      },
   },
   created: function () {
      this.init = moment().format("YYYY-MM-DD");
      this.final = moment().format("YYYY-MM-DD");
   },
   methods: {
      dialogResult_OK: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("getMovimientosExcel", {
                  init: this.init,
                  final: this.final,
               });
               if (res) {
                  saveAs(res.data, "Movimientos-" + this.init + "-" + this.final + ".xlsx");
                  this.show = false;
                  this.$emit("result_ok");
               } else {
                  throw res;
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$emit("result_cancel");
      },
      Show() {
         this.show = true;
      },
   },
};
</script>
