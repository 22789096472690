<template>
   <Movements/>
</template>

<script>
import Movements from "../components/Movimientos/Movements.vue"

export default {
   name: "Movimientos",
   components:{
      Movements
   }
};
</script>
